import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import styles from "./footer.module.css";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const handleLanguageChange = async (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={styles.footerBox}>
      <div className={styles.contextFooterBox}>
        <div className={styles.contextFooter}>
          <div className={styles.langBox}>
            <span className={styles.titleLangBox}>{t("siteLanguage")}</span>
            <div className={styles.languageButtons}>
              <button type="button" onClick={() => handleLanguageChange("en")} id="langEnButton">
                English
              </button>
              <button type="button" onClick={() => handleLanguageChange("de")} id="langDeButton">
                Deutsch
              </button>
              <button type="button" onClick={() => handleLanguageChange("fi")} id="langFiButton">
                Suomi
              </button>
              <button type="button" onClick={() => handleLanguageChange("fr")} id="langFrButton">
                Français
              </button>
              <button type="button" onClick={() => handleLanguageChange("es")} id="langEsButton">
                Español
              </button>
            </div>
          </div>
        </div>
        <p className={styles.sepLine} />
        <div className={styles.bottomInfoBox}>
          <div className={styles.legalNotices}>
            <span>© 2024 Siltacom Inc.</span>
            <div className={styles.legalNoticesLinks}>
              <Link to="/terms" className={styles.footerLink} id="termsLink">
                {t("termsAndConditions")}
              </Link>
              <Link to="/privacy" className={styles.footerLink} id="privacyLink">
                {t("privacyPolicy")}
              </Link>
            </div>
          </div>
          <div className={styles.infoBoxButtons}>
            <button type="button" id="facebookButton">
              <img src="/facebook.png" alt="facebook" />
            </button>
            <button type="button" id="instagramButton">
              <img src="/instagram.png" alt="instagram" />
            </button>
            <button type="button" id="twitterButton">
              <img src="/twitter.png" alt="X" />
            </button>
            <button type="button" id="linkedinButton">
              <img src="/linkedin.png" alt="linkedin" />
            </button>
            <button type="button" id="tiktokButton">
              <img src="/tiktok.png" alt="tik-tok" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
