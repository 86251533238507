import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./cookies.module.css";

function Cookies() {
  const { t } = useTranslation();
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setShowOverlay(true);
      setShowCookieConsent(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowCookieConsent(false);
    setShowOverlay(false);
  };

  const declineCookies = () => {
    setShowCookieConsent(false);
    setShowOverlay(false);
  };
  return (
    <>
      {showOverlay && <div className={styles.overlay} />}
      {showCookieConsent && (
        <div className={styles.cookieConsentContainer}>
          <img src="/cookie.png" alt="Cookies" className={styles.cookieImage} />
          <div className={styles.cookiesBox}>
            <p className={styles.weUseCookies}>{t("weUseCookies")} </p>
            <p className={styles.cookieText}>{t("useCookies")}</p>
            <Link to="/privacy" id="privacyLink" target="_blank" rel="noopener noreferrer">
              <p className={styles.privacyPolicy}>{t("privacyPolicy")}</p>
            </Link>
          </div>
          <div className={styles.cookieButtons}>
            <button type="button" onClick={acceptCookies}>
              Accept
            </button>
            <button type="button" onClick={declineCookies}>
              Decline
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Cookies;
