// eslint-disable-next-line import/no-unresolved
import { useCallback, useEffect, useState } from "react";
import ContactUs from "../blocks/contactUs/ContactUs";
import MainBlock from "../blocks/mainBlock/MainBlock";
import ResentWork from "../blocks/resentWork/ResentWork";
import styles from "./home.module.css";

function Home() {
  const [showScroll, setShowScroll] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.scrollY > 400) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [checkScrollTop]);

  return (
    <>
      <MainBlock />
      <ResentWork />
      <ContactUs />
      {showScroll && (
        <button type="button" className={styles.btn_up} onClick={scrollToTop} aria-label="Up">
          <img src="/up.png" alt="Scroll to Top" />
        </button>
      )}
    </>
  );
}

export default Home;
