import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./languageSelector.module.css";

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [dropdownList, setDropdownList] = useState(false);
  const location = useLocation();

  const handleLanguageChange = async (language) => {
    i18n.changeLanguage(language);
    setDropdownList(false);
  };

  return (
    <div className={styles.customDropdown}>
      <button
        type="button"
        className={
          location.pathname === "/" || window.innerWidth <= 768 ? styles.dropdownHeader : styles.dropdownHeader
        }
        onClick={() => setDropdownList(!dropdownList)}
      >
        <img
          src={location.pathname === "/" || window.innerWidth <= 768 ? `/globe-home.svg` : `/globe-home.svg`}
          alt="globe"
        />
        <span>{i18n.language.toUpperCase()}</span>
      </button>
      <div
        className={
          dropdownList
            ? location.pathname === "/" || window.innerWidth <= 768
              ? styles.dropdownListActive
              : styles.dropdownListActive
            : styles.dropdownListNoneActive
        }
      >
        <button type="button" onClick={() => handleLanguageChange("en")}>
          EN
        </button>
        <button type="button" onClick={() => handleLanguageChange("de")}>
          DE
        </button>
        <button type="button" onClick={() => handleLanguageChange("fi")}>
          FI
        </button>
        <button type="button" onClick={() => handleLanguageChange("fr")}>
          FR
        </button>
        <button type="button" onClick={() => handleLanguageChange("es")}>
          ES
        </button>
      </div>
    </div>
  );
}

export default LanguageSelector;
