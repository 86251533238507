import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import styles from "./mainBlock.module.css";
import i18n from "../../../locales/i18n";

function MainBlock() {
  const { t } = useTranslation();
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      document.documentElement.lang = lng;
    };
    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <div className={styles.mainBody}>
      <div className={styles.mainContainer}>
        <div className={styles.introContainer}>
          <div className={styles.subtitlesContainer}>
            <p className={styles.subtitles}>{t("digitalEfforts")}</p>
          </div>
        </div>
      </div>
      <div className={styles.introducingContainer}>
        <div className={styles.introducing}>
          <p className={styles.introducingText}>{t("introducingParagraph1")}</p>
          <p className={styles.introducingText}>{t("introducingParagraph2")}</p>
        </div>
      </div>
    </div>
  );
}

export default MainBlock;
