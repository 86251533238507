import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import LanguageSelector from "./LanguageSelector";

function Navbar() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (id) => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    setTimeout(() => {
      const element = document.getElementById(id);
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }, 0);
  };

  return (
    <div className={styles.navbarBox}>
      <div className={styles.navbarContainer}>
        <Link to="/" className={location.pathname === "/" ? styles.logoBoxHome : styles.logoBoxOther}>
          <img className={styles.logo} src="/logo.png" alt="synthetic-icon" />
        </Link>

        {/* <nav className={location.pathname === "/" ? styles.linkBox : ""}>
        <button type="button" onClick={() => handleClick("resentWork")} id="recentWorkButton">
          {t("recentWork")}
        </button>
          </nav> */}

        <nav className={styles.signBox}>
          <div className={styles.divider} />
          <LanguageSelector />
          <div className={styles.divider} />
          <button
            type="button"
            className={location.pathname === "/" ? styles.contactUsHome : styles.contactUsOther}
            onClick={() => handleClick("contactUs")}
            id="contactUsButton"
          >
            {t("contactUs")}
          </button>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
