import { useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import styles from "./contactUs.module.css";

function ContactUs() {
  const { t } = useTranslation();
  const [nameError, setNameError] = useState(false);
  const [name, setName] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  const handleNameChange = (e) => {
    const nameValue = e.target.value.trim();
    const isValidName = /^[a-zA-Z\u00C0-\u00FF\s'-]+$/.test(nameValue);
    setName(nameValue);
    setNameError(!isValidName);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.trim();
    const isValidEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(emailValue);
    setEmail(emailValue);
    setEmailError(!isValidEmail);
  };

  const checkFullCorrect = () => {
    return name && !nameError && email && !emailError;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSending(true);

    emailjs
      .send(
        "service_bedx8te",
        "template_nitjtcd",
        {
          name,
          email,
          message,
        },
        "YhxTayjW1_hV_5Lqf"
      )
      .then(() => {
        setIsSubmittedSuccessfully(true);
        setIsSending(false);
      })
      .catch(() => {
        setErrorMessage("Failed to send email. Please try again later.");
        setIsSending(false);
      });

    setName("");
    setEmail("");
    setMessage("");
  };

  const handleBackBtn = () => {
    // Reset form after successful submission
    setIsSubmittedSuccessfully(false);
    setErrorMessage("");
    setIsSending(false);
    setName("");
    setEmail("");
    setMessage("");
    setErrorMessage("");
  };

  return (
    <div id="contactUs" className={styles.mainContainer}>
      <div className={styles.contactUsContainer}>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit} className={styles.contactForm}>
            {!isSubmittedSuccessfully && !isSending && !errorMessage ? (
              <>
                <div className={styles.formGroup}>
                  <label htmlFor="name">{t("nameLabel")} *</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder={t("namePlaceholder")}
                    value={name}
                    onChange={handleNameChange}
                    required
                    pattern="^[a-zA-Z\u00C0-\u00FF\s'-]+$"
                  />
                  {nameError && (
                    <p className={styles.lineCheckValidPass}>
                      <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                      <span className={styles.errorMessage}>{t("nameErrorDescription")}</span>
                    </p>
                  )}
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="email">{t("emailLabel")} *</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={t("emailPlaceholder")}
                    value={email}
                    onChange={handleEmailChange}
                    required
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                  />
                  {emailError && (
                    <p className={styles.lineCheckValidPass}>
                      <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                      <span className={styles.errorMessage}>{t("emailErrorDescription")}</span>
                    </p>
                  )}
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="message">{t("messageLabel")} *</label>
                  <textarea
                    id="message"
                    name="message"
                    placeholder={t("messagePlaceholder")}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </div>
                <div className={styles.submitButtonContainer}>
                  <button type="submit" className={styles.submitButton} disabled={!checkFullCorrect()}>
                    {t("send")}
                  </button>
                </div>
              </>
            ) : (
              <div className={styles.successfulForm}>
                {isSending ? (
                  <p className={styles.sendingMessage}>{t("sendingMessage")}</p>
                ) : isSubmittedSuccessfully ? (
                  <>
                    <h1 className={styles.titlePage}>{t("Successful")}</h1>
                    <p className={styles.successfulFormText}>{t("FeedbackRequestConfirmation")}</p>
                    <button type="button" className={styles.backButton} onClick={handleBackBtn}>
                      {t("Back").toUpperCase()}
                    </button>
                  </>
                ) : (
                  <>
                    <p className={styles.errorMessage}>{errorMessage}</p>
                    <button type="button" className={styles.backButton} onClick={handleBackBtn}>
                      {t("tryAgain").toUpperCase()}
                    </button>
                  </>
                )}
              </div>
            )}
          </form>
        </div>
        <div className={styles.infoContainer}>
          <h2 className={styles.title}>{t("contactUsToday")}</h2>
          <p className={styles.text}>{t("contactDescription")}</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
