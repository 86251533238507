import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import styles from "./resentWork.module.css";
import i18n from "../../../locales/i18n";

function ResentWork() {
  const { t } = useTranslation();

  const handleClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      document.documentElement.lang = lng;
    };
    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <div className={styles.mainBody}>
      <div className={styles.mainContainer}>
        <p className={styles.title}>{t("recentWork")}</p>
        <img className={styles.image} src="/ai_man.jpg" alt="AI" />
        <p className={styles.subtitle}>{t("assistant")}</p>
        <p className={styles.subtitleParagraph}>{t("assistantText")}</p>
        <button
          type="button"
          className={styles.moreInfoButton}
          onClick={() => handleClick("moreInfo")}
          id="moreInfoButton"
        >
          {t("moreInfo")}
        </button>
      </div>
    </div>
  );
}

export default ResentWork;
